import React from 'react';

function Part() {
  return (
    <section id="Part">
    </section>
  );
}

export default Part;
