import React, { useState } from 'react';
import axios from 'axios';

import Ajv from 'ajv';

const ajv = new Ajv();

const schema = {
  "type": "object",
  "properties": {
    "name": {
      "type": "string"
    },
    "email": {
      "type": "string",
      "pattern": "^.+@.+$"
    },
    "message": {
      "type": "string"
    }
  },
  "required": ["name", "email", "message"]
};

const validate = ajv.compile(schema);

export function Contact() {
    
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState([]);
  
  const [loading, setLoading] = useState(false); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheck = async (e) => {
    e.preventDefault();
    const valid = validate(formData);
    if (valid) {
      console.log('Form data is valid:', formData);
      setErrors([]);
      handleSubmit(e);
    } else {
      console.log('Form data is invalid:', validate.errors);
      setErrors(validate.errors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    
    const formDataWithContent = {
        name: formData.name,
        email: formData.email,
        message: formData.message
    };
    
    console.log(formDataWithContent);
    
    const URL = "https://bfs8wkwg0g.execute-api.eu-west-2.amazonaws.com/dev/invoke";
    try {
        const response = await axios.post(URL, formData);
        console.log('Response:', response.data);
        setFormData({ name: '', email: '', message: '' });
    } catch (error) {
        console.error('Error:', error);
    } finally {
        setLoading(false);
    }
  };

  return (
    <div>
      <h2>Contact Form</h2>
      <form onSubmit={handleCheck} className='form'>
        <label htmlFor="name">Name:</label><br />
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required /><br />

        <label htmlFor="email">Email:</label><br />
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required /><br />

        <label htmlFor="message">Message:</label><br />
        <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows="4" required /><br />

        <input type="submit" value={loading ? 'Submitting...' : 'Submit'} disabled={loading} />
      </form>
    </div>
  );
}

export default Contact;
