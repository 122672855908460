import React, { useState, useEffect } from 'react';
import Work from './Work';
import Part from './Part';

function Experience({ id }) {
  const [showWork, setShowWork] = useState(false);
  const [showPart, setShowPart] = useState(false);

  useEffect(() => {
    if (id === 'work') {
      setShowWork(true);
      setShowPart(false);
    } else if (id === 'part') {
      setShowWork(false);
      setShowPart(true);
    } else {
      setShowWork(false);
      setShowPart(false);
    }
  }, [id]);

  return (
    <div>
      {showWork && (
        <div id="Work">
          <Work />
        </div>
      )}
      {showPart && (
        <div id="Part">
          <Part />
        </div>
      )}
    </div>
  );
}

export default Experience;
