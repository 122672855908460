import './App.css';
import React, { useState } from 'react';
import Education from './Education.js';
import Certs from './Certs.js';
import Experience from './Experience.js';
import Contact from './Contact.js';

import warning from './warning.png';

function App() {
  const [showEducation, setShowEducation] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showCerts, setShowCerts] = useState(false);
  const [experienceId, setExperienceId] = useState('');

  

  const handleEducationClick = () => {
    setShowEducation(true);
    setShowCerts(false);
    setShowExperience(false);
    setShowContact(false);
  };

  const handleStartClick = () => {
    setShowEducation(false);
    setShowCerts(false);
    setShowExperience(false);
    setShowContact(false);
  };

  const handleCertClick = () => {
    setShowEducation(false);
    setShowCerts(true);
    setShowExperience(false);
    setShowContact(false);
  };

  const handleExperienceClick = (id) => (event) => {
    event.preventDefault();
    setExperienceId(id);
    setShowEducation(false);
    setShowCerts(false);
    setShowExperience(true);
    setShowContact(false);
  };

  const handleContactClick = () => {
    setShowEducation(false);
    setShowCerts(false);
    setShowExperience(false);
    setShowContact(true);
  };

  return (
    <div className="start">
      <div className='background'></div>
      <div className="App">
        <p>This is pre-prod</p>
        <h1>ADAM<span className="highlight">BEJI</span></h1>
        <br />
        <nav className="navbar">
          <div>
            <ul className="nav navbar-nav">
              <li><a href="#start" onClick={handleStartClick}>Start</a></li>
              <li><a href="#Education" onClick={handleEducationClick}>Education</a></li>
              <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown" href="#experience">Experience
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  <li><a href="#work" onClick={handleExperienceClick('work')}>Work</a></li>
                  <li><a href="#Participations" onClick={handleExperienceClick('part')}>Participations</a></li>
                </ul>
              </li>
              <li><a href="#projects">Projects</a></li>
              <li><a href="#memberships">Memberships</a></li>
              <li><a href="#Contact" onClick={handleContactClick}>Contact</a></li>
              <li><a href="#Certs" onClick={handleCertClick}>Certs</a></li>
            </ul>
          </div>
        </nav>

        <img src={warning}></img>
        <h2>Website still in progress and receiving regular updates</h2>

        {showEducation && (
          <div id="Education">
            <Education />
          </div>
        )}
        {showCerts && (
          <div id="Certs">
            <Certs />
          </div>
        )}
        {showExperience && (
          <div id="Participations">
            <Experience id={experienceId} />
          </div>
        )}
        {showContact && (
          <div id="Contact">
            <Contact/>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
