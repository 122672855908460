import React, { useState, useEffect  } from 'react';

import img4 from './AWS.png';
import img5 from './AZ-900.png';
import img6 from './ITIL.png';
import img7 from './SC-900.png';
import img8 from './MS-900.png';
import img9 from './EA.png';
import img10 from './accenture.jpg';
import img13 from './cog.png';
import img12 from './jp-morgan.png';
import img11 from './verizon.png';
import img14 from './pepsico.jpg';

const images = [
  { src: img4, 
    info: `
    Cloud Computing Fundamentals
    AWS Services (EC2, S3, RDS, IAM)
    Global Infrastructure and Availability Zones
    Security Best Practices (IAM, encryption, network security)
    AWS Billing and Cost Management
    Deployment and Operations on AWS
    Compliance and Governance on AWS
    Troubleshooting AWS Services
    ` },
  { src: img7, info: 'AWS Certification' },
  { src: img5, info: 'AZ-900 Certification' },
  { src: img6, info: 'ITIL Certification' },
  { 
    src: img12, 
    info: `
      J.P. Morgan Software Engineering Virtual Experience on Forage - May 2024

      - Set up a local dev environment by downloading the necessary files, tools and dependencies.
      - Fixed broken files in the repository to make web application output correctly.
      - Used JPMorgan Chase’s open source library called Perspective to generate a live graph that displays a data feed in a clear and visually appealing way for traders to monitor.
    `,
    onClickUrl: 'https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/R5iK7HMxJGBgaSbvk_J.P.%20Morgan_BMo38gniajCoALb73_1716202220935_completion_certificate.pdf'
  },
  

  { src: img8, info: 'AWS Certification' },
  { src: img9, 
    info: `
            Electronic Arts Software Engineering virtual experience program on Forage -
        February 2023


        * Proposed a new feature for the Sims 4 and wrote a Feature Proposal describing
        it to other stakeholders.
        * Built a class diagram and created a header file in C++ with class definitions
        for each object.
        * Patched a bugfix and optimized the Sims 4 codebase by implementing an
        improved data structure.
    ` },
  { src: img10, 
    info: `
        Accenture UK Developer and Technology Virtual Experience Programme on Forage -
    January 2023


    * Completed the Developer and Technology Job Simulation where I developed an
    end to end understanding of the Software Development Lifecycle.
    * Conducted in-depth research into emerging technology trends, particularly in
    the field of DevOps, demonstrating a proactive approach to staying informed
    about the rapidly evolving tech landscape.
    * Created and delivered a compelling PowerPoint presentation analysing and
    comparing Waterfall and Agile methodologies.
    * Designed a custom algorithm using pseudocode and effectively communicated its
    logic by creating a detailed flow diagram.
    * Debugged a program written in Python by fixing syntax and logic errors in the
    code.
    ` },
  { src: img13, 
    info: `
        Cognizant Agile Methodology Job Simulation on Forage - February 2023


    * Demonstrated an understanding of key project management concepts by creating
    a comprehensive presentation analysing strengths and weaknesses of Agile and
    Waterfall methodologies.
    * Applied knowledge of key aspects of Agile methodology by identifying user
    stories for an innovative interplanetary video-conferencing application and
    creating a document detailing Scrum role assignments and ceremonies.
    * Showcased problem-solving skills by diagnosing and proposing solutions for
    development process issues encountered during sprints.
    ` },
  { src: img11, 
    info: `
    Verizon Cloud Platform Job Simulation on Forage - May 2024
    * Completed a job simulation involving building a hypothetical new VPN product
    for Verizon’s Cloud Computing team.
    * Used command line Python to test whether Verizon’s VPN met the cloud-native
    traits, i.e. redundancy, resiliency and least-privilege.
    * Researched approaches to achieve application security and communicated
    insights in a PowerPoint Presentation.
    `
  },
  { src: img14, 
    info: `
        PepsiCo Advanced Software Engineering Job Simulation on Forage - May 2024


        * Completed a simulation focused on building a new system for PepsiCo's
        Software Engineering team
        * Drafted a visualization and storyboard of engineering tickets for the system
        development
        * Created a continuous deployment protocol using CI/CD and Azure `
  },
];
function Certs() {
    const [hoveredImage, setHoveredImage] = useState(null);
    const handleImageClick = (url) => {
        window.location.href = url;
      };
  return (
    <div>
        <div className="scroll-container" id="experience">
        {images.map((image, index) => (
            <div
            key={index}
            className="image-wrapper"
            onMouseEnter={() => setHoveredImage(image.info)}
            onMouseLeave={() => setHoveredImage(null)}
            onClick={image.onClickUrl ? () => handleImageClick(image.onClickUrl) : null}
            >
            <img src={image.src} alt={image.info} className="image" />
            </div>
        ))}
        </div>
        <div className="info-box">
        {hoveredImage ? <p>{hoveredImage}</p> : 
        <>
          <p>Hover over image for brief description</p>
          <p>Select image to view certificate</p>
        </>}
      </div>
    </div>
  );
}

export default Certs;
