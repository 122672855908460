import React from 'react';

function Education() {
  return (
    <section id="Education">
    </section>
  );
}

export default Education;
